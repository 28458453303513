<template>
  <div class="column q-gutter-md">
    <div :id="`blocklyDiv-${action}`" style="height: 50vh"></div>
    <div class="row">
      <q-btn class="col" @click="start" label="Démarrer" />
    </div>
    <div class="q-pa-md inset-shadow column">
      <q-scroll-area ref="scrollArea" class="q-pa-md" style="height: 25vh;">
        <q-chat-message class="q-ma-md" v-for="(m, i) in msg" :key="i" :text="[m.text]" :name="m.name" :bg-color="m.bg"
          :sent="m.me" />
      </q-scroll-area>
      <q-input square filled autogrow :disable="!enableInput" v-model="msgText" @keyup.enter="sendMsg"
        placeholder="Envoyer un message" />
    </div>
  </div>
</template>

<script>
// Import Blockly core.
import * as Blockly from 'blockly/core';
// Import the default blocks.
// eslint-disable-next-line
import * as libraryBlocks from 'blockly/blocks';
// Import a generator.
// eslint-disable-next-line
import { javascriptGenerator, Order } from 'blockly/javascript';
// Import a message file.
import * as Fr from 'blockly/msg/fr';

// My imports
import toolbox from '@/utils/blockly_default_toolbox';

Blockly.setLocale(Fr);

Blockly.defineBlocksWithJsonArray([{
  "type": "display",
  "message0": 'afficher %1',
  "args0": [
    {
      "type": "input_value",
      "name": "VALUE",
    }
  ],
  "previousStatement": null,
  "nextStatement": null,
  "colour": "#DD0000",
  "tooltip": "Affiche un message",
}]);

Blockly.defineBlocksWithJsonArray([{
  "type": "answer",
  "message0": 'réponse',
  "output": null,
  "colour": "#DD0000",
  "tooltip": "Attend une réponse",
}]);

Blockly.defineBlocksWithJsonArray([{
  "type": "wait",
  "message0": 'attendre %1 secondes',
  "args0": [
    {
      "type": "field_number",
      "value": 1,
      "name": "SECONDS",
    }
  ],
  "previousStatement": null,
  "nextStatement": null,
  "colour": "#DD0000",
  "tooltip": "Attend un certain nombre de secondes",
}]);

Blockly.defineBlocksWithJsonArray([{
  "type": "ai_chatbot_answer",
  "message0": 'réponse du chatbot à %1',
  "output": "String",
  "args0": [
    {
      "type": "input_value",
      "name": "MESSAGES",
    }
  ],
  "colour": "#CCCC00",
  "tooltip": "Attend une réponse",
}]);

Blockly.defineBlocksWithJsonArray([{
  "type": "ai_chatbot_complete",
  "message0": 'compléter %1',
  "output": "String",
  "args0": [
    {
      "type": "input_value",
      "name": "TEXT",
    }
  ],
  "colour": "#CCCC00",
}]);

Blockly.defineBlocksWithJsonArray([{
  "type": "ai_chatbot_message",
  "message0": 'message de %1 : %2',
  "output": "ChatbotMessage",
  "args0": [
    {
      "type": "field_dropdown",
      "name": "ROLE",
      "options": [
        ["Utilisateur", "user"],
        ["Chatbot", "assistant"],
      ]
    },
    {
      "type": "input_value",
      "name": "CONTENT",
      "check": "String",
    },
  ],
  "colour": "#CCCC00",
  "tooltip": "Transforme un texte en message pour le chatbot",
}]);

// deep copy of the toolbox
var toolbox2 = JSON.parse(JSON.stringify(toolbox));
toolbox2.contents.push({
  kind: 'sep',
});
toolbox2.contents.push({
  kind: "category",
  name: "IO Blocks",
  colour: "#DD0000",
  contents: [
    {
      kind: "block",
      type: "display",
    },
    {
      kind: "block",
      type: "answer",
    },
    {
      kind: "block",
      type: "wait",
    },
  ],
});
toolbox2.contents.push({
  kind: "category",
  name: "AI Blocks",
  colour: "#CCCC00",
  contents: [
    {
      kind: "block",
      type: "ai_chatbot_answer",
    },
    {
      kind: "block",
      type: "ai_chatbot_message",
    },
    {
      kind: "block",
      type: "ai_chatbot_complete",
    },
  ],
});

// javascript code generator for the custom block
javascriptGenerator.forBlock["display"] = function (block) {
  var value = javascriptGenerator.valueToCode(block, 'VALUE', javascriptGenerator.ORDER_NONE) || 'undefined';
  return 'runenv.display(' + value + ');\n';
};

javascriptGenerator.forBlock["wait"] = function (block) {
  var value = block.getFieldValue('SECONDS');
  return `await new Promise(resolve => setTimeout(resolve, ${value * 1000}));\n`;
};

javascriptGenerator.forBlock["answer"] = function (block) {
  block;
  return ['await runenv.get_answer()', Order.NONE];
};

javascriptGenerator.forBlock["ai_chatbot_answer"] = function (block) {
  var value = javascriptGenerator.valueToCode(block, 'MESSAGES', javascriptGenerator.ORDER_NONE) || 'undefined';
  return [`(await runenv.$api.ai.get_chatbot_multi_answer(${value})).content`, Order.NONE];
};

javascriptGenerator.forBlock["ai_chatbot_message"] = function (block) {
  var value = javascriptGenerator.valueToCode(block, 'CONTENT', javascriptGenerator.ORDER_NONE) || 'undefined';
  // get the role
  var role = block.getFieldValue('ROLE');
  return [`{ role: "${role}", content: ${value}, toString() { return JSON.stringify(this); } }`, Order.NONE];
};

javascriptGenerator.forBlock["ai_chatbot_complete"] = function (block) {
  var value = javascriptGenerator.valueToCode(block, 'TEXT', javascriptGenerator.ORDER_NONE) || 'undefined';
  return [`(await runenv.$api.ai.get_chatbot_complete(${value})).content`, Order.NONE];
};

export default {
  props: ["course", "action", "path"],
  data() {
    return {
      looptrap: null,
      msg: [],
      msgText: null,
      sent: null,
      enableInput: false,
      code: null,
    };
  },
  methods: {
    sendMsg() {
      this.sent = true;
    },
    highlightBlock(id) {
      this.workspace.highlightBlock(id);
    },
    display(value) {
      this.msg.push({ name: "Ordinateur", text: value });
      // Scroll to the bottom of the chat next tick
      setTimeout(() => {
        this.$refs.scrollArea.setScrollPercentage("vertical", 1.1, 500);
      }, 100);
    },
    get_answer() {
      this.enableInput = true;
      return new Promise((resolve) => {
        const checkInput = () => {
          if (this.sent) {
            this.enableInput = false;
            this.msg.push({ name: "Moi", text: this.msgText, me: true });
            resolve(this.msgText);
            this.msgText = null;
            this.sent = false;
          } else {
            setTimeout(checkInput, 100); // Vérifie toutes les 100ms si un message a été entré
          }
        };
        checkInput();
      });
    },
    generateCode() {
      this.looptrap = (this.course.looptrap ? this.course.looptrap : 1000);
      javascriptGenerator.addReservedWords('highlightBlock');
      javascriptGenerator.STATEMENT_PREFIX = 'runenv.highlightBlock(%1);';
      javascriptGenerator.INFINITE_LOOP_TRAP = 'if(--runenv.looptrap < 0) throw "Boucle infinie";';
      this.code = javascriptGenerator.workspaceToCode(this.workspace);
      // alert(this.code);
    },
    async runCode() {
      var runenv = this;
      runenv;
      var code = this.code;
      this.msg = [];
      this.enableInput = false;
      this.msg.push({ name: "Ordinateur", text: "Début du programme", bg: "grey" });
      try {
        await eval(`(async () => { ${code} })()`);
      } catch (e) {
        this.msg.push({ name: "Ordinateur", text: `ERREUR : ${e}`, bg: "red" });
        this.msg.push({ name: "Ordinateur", text: this.code, bg: "red" });
      }
      this.msg.push({ name: "Ordinateur", text: "Fin du programme", bg: "grey" });
      this.enableInput = false;
    },
    loadResponse() {
      (async () => await this.$api.response.get_last(
        (await this.$api.user.me()).username,
        await this.$oid.compute_course_oid(this.course)
      ))().then((r) => {
        if (r != undefined) {
          Blockly.serialization.workspaces.load(JSON.parse(r), this.workspace);
        }
      }).catch((err) => {
        console.error(err);
      });
    },
    saveResponse() {
      (async () => await this.$api.response.put(
        (await this.$api.user.me()).username,
        await this.$oid.compute_course_oid(this.course),
        JSON.stringify(Blockly.serialization.workspaces.save(this.workspace))
      ))();
    },
    start() {
      this.generateCode();
      this.saveResponse();
      this.runCode();
    },
  },
  async mounted() {
    // Create a div to hold the Blockly workspace.
    this.workspace = Blockly.inject(`blocklyDiv-${this.action}`, {
      toolbox: toolbox2,
      renderer: 'zelos',
      zoom:
      {
        controls: false,
        wheel: true,
        startScale: 0.5,
        maxScale: 1.2,
        minScale: 0.2,
        scaleSpeed: 1.02,
        pinch: true
      },
    });
    this.loadResponse();
  }
};
</script>
