<template>
  <div v-if="path && path.length > 0" class="bypass">
    <component :is="'Course' + course.elements[path[0]].content.type" :course="course.elements[path[0]].content"
      :action="action + '/' + path[0]" :path="path.slice(1)"></component>
  </div>
  <div :class="{ 'avoid-break-inside': course.avoid_break }" v-for="[i, e] of course.elements.entries()" :key="i">
    <component :is="course.title_tag || 'h6'">{{ getOlPrefix(i) }}{{ e.title }}</component>
    <component :is="'Course' + e.content.type" :course="e.content" :action="action + '/' + i"></component>
  </div>
</template>


<style scoped>
@media print {
  .avoid-break-inside {
    break-inside: avoid;
  }
}
</style>

<script>
import components from '@/components/Course';
import { genOlPrefix } from '@/utils/ol';

export default {
  components: components,
  props: ["course", "path", "action"],
  methods: {
    getOlPrefix(i) {
      return genOlPrefix(this.course.oltype, ". ", i);
    },
  },
};
</script>
