<template>
  <div class="column">
    <q-input v-model="c.description" filled dense label="Description" type="test"
      @update:model-value="$emit('update:course', c)" />
    <q-input v-model.number="c.looptrap" filled dense label="Limite d'iterations" type="number"
      @update:model-value="$emit('update:course', c)" />
  </div>
</template>

<script>
export default {
  props: ["course"],
  emits: ["update:course"],
  data() {
    return {
      c: this.course,
    };
  },
};
</script>
