import client from './client';

export default {
  async get_chatbot_multi_answer(messages) {
    return await client.post(`/ai/chatbot/multi/answer`, { messages });
  },
  async get_chatbot_complete(prompt) {
    return await client.post(`/ai/completion/answer`, { prompt }, { params: { model: "gpt-3.5-turbo-instruct" } });
  },
};
